
/* Media Queries for Home Page */
/* Testimonial  */
/* Responsiveness for Testimonial */
/* Typography */
/* About.js Typography*/
/* FeaturedWork.js Typography */
/* Portfolio Typography */
/* Navigation */
/* Carousel */
/* Portfolio Header */
/* Book Edits */
/* CopyWriting */
/* Blog Writing CSS */
/* Newsletter Column  */
/* Read with Coffee */
/* Book Reviews */
/* Contact Form Styling */
/* Portfolio Header Styling*/
/* Blog Media Queries */

#about,
#work,
#testimonial,
#services,
#contact {
  padding-top: 100px;
  padding-bottom: 100px;
}


@media only screen and (max-width: 640px) {
  #about,
  #work,
  #testimonial,
  #services,
  #contact {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  }


.noSelect:hover {
  background-color: #dcdcdc;
}


/* Media Queries for Home Page */

.noSelect:focus {
  outline: none !important;
}

@media only screen and (max-width: 640px) { 
 .nav-brand {
   width: 350px;
   padding-bottom: 5px;
 }

 .splash-image {
   padding-top: 5px;
 }

 .work-blocks {
   padding-bottom: 16px;
 }

 .tst p {
   text-align: justify;
 }

 .tst-row {
   margin-left: 25px;
 }

 .services-block {
   padding-bottom: 16px
 }

}

/* Testimonial  */

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 459px;
  height: 400px;
}


/* Responsiveness for Testimonial */

@media only screen and (min-width: 1111px) { 

  .tst-author-cindy {
    margin-top:  25px;
    padding-left: 0px;
    padding-right: 12px;
  }

  .tst-author-russell {
    padding-left: "0px";
    padding-right: "12px";
    margin-top: "20px"
  }

  .tst-author-cindy {
    padding-top: 20px;
  }

  .tst-author-mattera {
    padding-top: 20px;
  }
}

/* Responsiveness for Testimonial */

@media only screen and (max-width: 640px) { 
  .swiper-container-initialized {
    height: 475px;
  }
 
  .tst-author-mattera {
    padding-top: 20px;
  }

  .swiper-pagination {
    bottom: -6px;
    left: -70px;
    width: 104%;
  }

  .swiper-pagination-bulletsswiper-pagination-bullets {
    width: 350px;
  }
}
  

/* __________________________________________________________ */

/* Typography */
h1{
  margin: 0;
  font-family: 'Karla', sans-serif;
  color: #808080
}

h2 {
  font-size: 25px;
  color: #454545
}



h4 {
  font-size: 20px;
  font-family: 'Karla', sans-serif;

}

h5 {
  font-size: 18px;
  font-family: 'Karla', sans-serif;
  color: #505050;

}

p {
  font-size: 18px;
  line-height: 30px;
}

body {
  font-family: 'Karla', sans-serif;
  color: #757575;
}


a.nav-link {
  font-size: 18px;
}



/* About.js Typography*/

.about-text p{
  padding-top: 6px
  
}

.about-text h5{
  margin-top: 10px;
  margin-bottom: 10px
}



/* FeaturedWork.js Typography */

.featured-work-text h4{
  letter-spacing: 0.5px;
  line-height: 30px;
}

.featured-work-text a{
  color:#808080;
}

.card-body {
  color: #454545;
}

.contact-form h4 {
  line-height: 30px;
}


/* Select Writing */

/* #select-writing {
  background-color: #e4b2b1;
}

.select-writing-card {
  width: 400px;
  height: 400px;
  background-color: #d41b18;
  border-radius: 0%;
  border-color: #f3ce88;
  border-width: .25em;
} */


/* Professional Services */
.svs {
  background-color: #d3d3d3;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: "center";
}


.svs_list {
  border: none;
  background-color: #f3ce88;
}

.svs_list ul {
  list-style-type: none;
  padding-left: 2rem;
  padding-right: 2rem

}



/* Portfolio Typography */

#featuredwork h1 {
  color:#454545
}

#featuredwork p{
  color:#454545;
  font-size: 19px

}

.contact-form h4{
  margin-top: 20px;
}

.tst-author-info p {
  font-size: 12px
}

.edits-proofs p {
  color: #454545 !important;
}

.edits-proofs ul {
  list-style-type: none;

}

.edits-proofs li {
  font-size: 20px;
}

.edits-proofs a {
  margin: 6px;
}

/* __________________________________________________________ */

/* Navigation */

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.80)
}



/* Carousel */
.tst-author-info img {
  border-radius: 100%;
}


/* Portfolio Header */

.port-header-box {
  background-color: #e4b2b1;
  border-radius: 0%;
  border-color: #f3ce88;
  border-width: .25em;
  padding: 50px;
}


.new-button {
  border-radius: 0;
  background-color: #EBECF0;
  padding-left: 31.05px;
  padding-right: 31.05px;
  padding-top: 18.6px;
  padding-bottom: 18.6px;
  border: none;
}



@media (min-width: 800px) {

  #featureHeader {
    display: "flex";
  }

  #notetoreader {
    float: "right"
  }
  
  #note {
    margin-left: 40px;
  }

}

@media (max-width: 600px) {
  #note {
    padding-top: 20px
  }
}

/* __________________________________________________________ */

/* Book Edits */
#bookedits {
  background-color: #e4b2b1
}

#motion {
  position: relative;
  top: 0; 
  transition: top ease 0.5s
}

#motion:hover{
  top: -10px
}

/* __________________________________________________________ */

/* CopyWriting */


#copywriting {
  background-color: #b6ccc8;
}

#copy-project a {
  color: inherit;
}

.copywriting-card {
  background-color: #b6ccc8;
  border-radius: 0%;
  border-color: #f3ce88;
  border-width: .25em;
}


/* __________________________________________________________ */

/* Blog Writing CSS */

#personal {
  background-color:  #f3ce88
  ;
}
.blogHeader {
  font-size: 30px;
  font-weight: 400px;
  text-align: center;
  color: white;
  line-Height: 150px;
  height: 150px;
}

.blog-list {
  font-family: 'Cinzel', serif;
  font-size: 14px;
 
}

.blog-card {
  border: none;
 
}

.blog-card:hover {
  filter: opacity(90%) brightness(150%) saturate(100%);
  transition:  ease-in-out .30s;
}

.blog-list {
  padding-top: 20px;

}

.blog-list ul {
  list-style-type: none;
  padding-left: 0px;
}

.blog-list a{
  color: inherit;
  letter-spacing: 0.5px;
  
}

/* __________________________________________________________ */
/* Newsletter Column  */
.newsletter-col {
  padding-left: 0px;
  padding-right: 0px;
  font-family: 'Karla';
  font-weight: 200
}

.newsletter-card {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #808080;
  color: white;
  border: none;
  border-radius: 0px; 
  text-align: center;
  height: 100%;
  padding-top: 20px;
  font-size: 18px
}

.newsletter-card ul {
  list-style-type: none;
  padding-top: 20px;
}

.newsletter-card ul a{
  color: inherit;
}

/* __________________________________________________________ */
/* Read with Coffee */


/* On Writing Books Column */

.card-edit {
  padding-left: 0px;
  padding-right: 0px;
  border: none;
  border-radius: 0px; 
}

/* Favorite Books and Authors Column */

.card-favorite {
  border-radius: 0px;
  padding-right: 0px;
  border: none;
  border-radius: 0px; 
}


/* Books reviews  */

.reviews-col {
  padding-left: 0px;
  padding-right: 0px;
}

.reviews-card {
  /* text-align: center; */
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  border: none;
  border-radius: 0px; 
  font-family: 'Karla';
  font-weight: 400;
  color: #808080;
}

.reviews-card ul {
  list-style-type: none;
  font-size: 18px;
}

.reviews-card ul a{
  color: inherit;
}

.reviews body {
  font-size: 20px;
}

/* __________________________________________________________ */
/* Footer */

#footer {
  background-color: #EBECF0;
}


.fab {
  padding-left: 20px;
}

.svg-inline--fa {
  margin: 10px;
}

.social-icons a {
  color: inherit;
}

/* __________________________________________________________ */
/* Read with Coffee */

.rwc-container {
  padding-bottom: 40px;
}

.rwc-heading {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 18px;
}

.rwc-button {
  background-color: #EBECF0;
  border-radius: 0;
  border: none;
}


.btn {
  color: black;
  font-family: 'Karla';
  /* font-weight: 100; */
  font-size: 18px;
  letter-spacing: 0.25px;
}

.btn:hover{
  color: #EBECF0;
  background-color: #808080;
}

.rwc-cards-overlay {
  font-size: 18px;
  text-align: center;
  height: 200px;
  line-height: 200px;
}


.rwc-reviews {
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 18px;
}

.rwc-faves {
  /* padding-top: 20px; */
  padding-bottom: 40px;
  font-size: 18px;
}

.rwc-reviews ul {
  list-style-type: none;;
}

.rwc-reviews a {
  color: inherit;
}

.rwc-button-one {
  background-color: #808080;
  color: white;
  width: 125px;
  height: 24px;
  padding-left: 20px
  
}

.rwc-col-2 {
  padding-top: 40px;
}

.rwc-subtitle {
  text-align: center;
  padding-bottom: 20px;
}


@media only screen and (max-width: 640px) {
  .rwc-col-blog {
    padding-bottom: 20px
  }

  .rwc-col-newsletter {
    padding-bottom: 20px
  }

  .rwc-col-2 {
    padding-top: 20px;
  }
}


/* Book Reviews */

.book-rws {
  padding-top: 40px;
}

.book-rws ul {
  list-style-type: none;

}

.book-rws a {
  color: inherit;  
}

.book-rws-list {
  padding-top: 20px;
  font-size: 20px;
  line-height: 40px;
  font-family: "Karla";
  font-weight: 200px;
}


/* Contact Form Styling */

.form-control {
  border: none;
  font-size: 20px;
}





/* 800x or more */
@media (min-width: 800px) {
  #copy-project {
    padding-top: 20px;
  }
  .blogHeader {
    line-Height: 150px;
    height: 70px;
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  .blogHeader {
    line-Height: 40px;
    height: 20px;
    font-size: 12px;
;
}}
/* 400px or less */
@media (max-width: 400px) {
  .blogHeader {
    line-height: 150px;
    height: 319px
  }
}

/* 800px or more */
@media (min-width: 1000px) {
  #copy-project {
    padding-top: 20px;
  }
}

/* New CSS */

#guestwriting { 
  background-color: #f3ce88;
}

#guestwriting  a {
  color: #808080
}


#blogwriting {
  background-color: #b6ccc8;

}

#novelwriting {
  background-color: #e4b2b1;
}

.element-visible { display: block }

.element-hidden { display: none }

@media only screen and (max-width: 640px) {
  p {
    font-size: 14px !important;
  }

  h4 {
    letter-spacing: 0.5px
  }

  .services {
    font-size: 16px
  }
}


/* Portfolio Header Styling*/

@media only screen and (min-width: 951px) {
  #notetoreader {
    padding: 80px;
  }
}

@media only screen and (max-width: 640px) {
  #notetoreader {
    padding: 40px;
  }
}

.port-subtitle {
  padding: 20px;
  text-align: center;
  color: #454545

}

.port-pagetitle {
  padding-top: 80px;
  padding-bottom: 60px;
  text-align: center;
}

#bookedits a {
  color: #808080
}

/* __________________________________________________________ */
/* Blog Media Queries */

/* Breakpoint for Desktop */


@media only screen and (min-width: 1110px) {
  .blog-photo-grid {
    padding-bottom: 80px;
  }

  .blog-photo {
    width: 440px; 
    height: 330px;
  }

  .blog-photo {
    padding: 10px
  }
}

@media only screen and (min-width: 1111px) and (max-width:1170px) {

.blog-photo {
  width: 180px;
  height: 129px;
  padding: 5px;
}
  
.blog-photo-grid{
  padding-bottom: 80px;
  padding-left: 45px;
}

}


/* Breakpoint for Tablet */

@media only screen and (min-width: 641px) and (max-width:1110px) {
  .blog-photo {
    width: 180px;
    height: 129px;
    padding: 5px;
  }
  

.blog-photo-grid{
  padding-bottom: 80px;
  padding-left: 45px;
}

}

/* Breakpoint for Phone */

@media only screen and (max-width: 640px) {
  .blog-photo {
    width: 172px;
    height: 129px;
    padding: 5px;
  }

  
.blog-photo-grid{
  padding-bottom: 80px;
  padding-left: 10px;
}

  .photo-col {
    padding-left: 0px;
  }

}